.gender-dropdown-box {
  max-width: 70px;
  width: 100%;
}

.gender-drpdown-flex {
  display: flex;
  align-items: center;
}


.advertisement-card {
  margin-bottom: 20px;
}

.view-advertiser-details-card .admin-sub-title-main {
  margin-bottom: 15px;
}

.admin-input-box.advertiser-filter-input {
  margin-bottom: 0px;
}

.filter-card {
  margin-bottom: 15px;
}

body .select-check-itme {
  padding: 0px 0px 5px 0px;
}

body .select-check-box {
  padding: 0px 10px;
}

body .select-check-box svg {
  font-size: 17px;
  color: #aeb2b7fa;
}

body .select-check-text .MuiTypography-root {
  color: var(--secondary-color);
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
}

.admin-auto-complete-control .admin-check-dropdown-select.MuiOutlinedInput-root {
  padding: 0px !important;
}

.admin-date-input-main .date-rangepicker-main {
  min-width: 100% !important;
  max-width: 100%;
  width: 100%;
}

.admin-date-input-main .date-rangepicker-main input {
  padding: 7px 14px;
  border: 1px solid var(--main-border);
  border-radius: 4px;
  color: var(--secondary-color);
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
}

.admin-date-input-main .date-rangepicker-main fieldset {
  border: 0px;
}

.admin-date-input-main .MuiStack-root {
  padding: 0px;
}

.admin-date-input-main .MuiInputBase-root {
  padding-right: 0px;
}

.MuiDateRangeCalendar-root>div:first-child {
  display: none;
}

.filter-btns .btn-main-primary .btn-primary,
.filter-btns .btn-main-primary .btn-primary:hover {
  padding: 9.14px 10px;
  background-color: var(--selected-color);
}

.event-add-flex {
  display: flex;
  gap: 15px;
  align-items: center;
}

.event-add-flex .add-event-btn {
  min-width: 30px;
}

.event-add-flex .admin-add-event-input-box {
  width: 100%;
}

.admin-advertiser-table-main .table {
  min-width: 1400px;
}

.add-row-flex {
  display: flex;
  align-items: center;
  gap: 10px;
}

.add-row-flex .add-row-btn {
  min-width: 30px;
}

.admin-incress-dicress-icon {
  height: 15px;
  width: 15px;
  object-fit: contain;
}

.add-digit-row .add-digit-text {
  color: var(--bg-color);
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
}

.add-digit-row {
  display: flex;
  gap: 10px;
}

.add-digit-input-box {
  width: 100%;
}

.add-row-input-box {
  flex: 1;
}

.billing-info-btn-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-slot-box {
  border: 1px solid var(--main-border);
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 19px;
  font-weight: 500;
  margin: 0px 0px 6px;
  color: var(--selected-color);
  min-height: 19px;
  min-width: 60px;
  text-align: center;
}

.slot-plus-icon {
  height: 8px;
  width: 8px;
  object-fit: contain;
}

.slot-content-main .slot-creative-icon-btn,
.slot-content-main .slot-creative-icon-btn:hover {
  height: 15px;
  width: 15px;
  min-width: 15px;
  padding: 0;
  border: 1px solid var(--main-border);
  border-radius: 5px;
  background-color: #908585;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slot-flex .slot-creative-select-text {
  border: 1px solid var(--main-border);
  border-radius: 5px;
  padding: 1px 8px;
  font-size: 10px;
  line-height: 14px;
  font-weight: 500;
  color: var(--secondary-color);
  text-align: center;
}

.slot-flex {
  display: flex;
  column-gap: 5px;
  row-gap: 5px;
  margin-bottom: 5px;
  max-width: 185px;
  min-width: 185px;
  flex-wrap: wrap;
}

.slot-creative-icon-flex {
  display: flex;
  gap: 5px;
  align-items: center;
}

.slot-value-lable {
  font-size: 10px;
  line-height: 16px;
  font-weight: 500;
  color: var(--secondary-color);
  margin-right: 3px;
}

.slot-value-total {
  font-size: 10px;
  line-height: 16px;
  font-weight: 500;
  color: var(--primary-color);
  margin-left: 3px;
}

.slot-creative-icon-flex .slot-creative-progress-btn {
  padding: 0;
  border: 1px solid var(--main-border);
  border-radius: 5px;
  font-size: 10px;
  line-height: 16px;
  font-weight: 500;
  color: var(--secondary-color);
}

.table-td .admin-table-slot-box {
  padding: 1px;
  border-radius: 3px;
  color: var(--selected-color);
  text-align: center;
  font-weight: 600;
  border: 1px solid var(--selected-color);
  font-size: 11px;
  line-height: 14px;
  white-space: nowrap;
}

.view-content-data-flex .view-squre-box {
  border: 1px solid var(--main-border);
  border-radius: 5px;
  padding: 1px 5px;
  font-size: 11px;
  line-height: 15px;
  color: var(--secondary-color);
}

.date-picker-body .MuiButtonBase-root.Mui-selected{
  background-color: #bdbdbd;
}
.date-picker-body .MuiButtonBase-root{
  padding: 2px;
  font-size: 11px;
}
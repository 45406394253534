.add-checkbox-flex {
      display: flex;
      gap: 17px;
      flex-wrap: wrap;
}

.admin-modal .admin-add-user-modal-inner-main.admin-cast-profile-modal {
      max-width: 300px;
}

.admin-profile-main,
.cast-view-content {
      display: flex;
      justify-content: center;
      align-items: center;
}
.uoload-post-video-main .uoload-post-video{
      width: 100%;
      border-radius: 5px;
      object-fit: contain;
      height: 200px;
      margin: auto;
      display: flex;
}
.admin-modal .admin-add-user-modal-inner-main.admin-post-video-modal {
      max-width: 650px;
  }

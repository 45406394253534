.admin-modal .admin-add-news-modal-inner-main {
    max-width: 800px;
  }
  
  .admin-contol-location fieldset {
    border: 1px solid var(--main-border);
  }
  
  .admin-input-box.admin-add-news-input-box {
    margin-bottom: 0px;
  }
  
  .news-tabs-main {
    margin-bottom: 15px;
  }
  
  .admin-news-check-main {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
  }
  
  .admin-form-group .admin-date-picker-control {
    width: 100%;
    border: 1px solid var(--main-border);
    border-radius: 4px;
  }
  .admin-date-input-main .admin-form-group .admin-date-picker-control input {
    border: 0px;
    padding: 8px 15px;
  }
  
  .admin-contol-location fieldset {
    border: 1px solid var(--main-border);
  }
  
  .news-tabs-main {
    margin-bottom: 15px;
  }
  
  .news-content-main {
    /* margin: 0px 0px 20px; */
    margin: 0px 0px 10px;
  }
  
  .news-content-main .news-name-text {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    color: var(--selected-color);
    margin-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  
  .news-content-main .news-discription-text {
    font-size: 12px;
    line-height: 22px;
    color: var(--secondary-color);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
  
  .admin-page-title-main .admin-view-page-title {
    color: var(--primary-color);
    font-size: 19px;
    line-height: 22px;
    font-weight: 600;
    position: relative;
    padding-left: 10px;
  }
  
  .admin-page-title-main .admin-view-page-title::after {
    content: "";
    position: absolute;
    height: 22px;
    width: 4px;
    background-color: var(--selected-color);
    left: 0px;
  }
  
  .play-icon {
    margin-right: 5px;
  }
  
  .highlight-box {
    display: flex;
    margin-bottom: 10px;
    position: relative;
  }
  
  .highlight-flex {
    display: flex;
    gap: 10px;
  }
  
  .highlight-flex .highlight-content-lable {
    font-size: 13px;
    font-weight: 500;
    line-height: 17px;
    color: var(--bg-color);
  }
  
  .highlight-flex .highlight-content-data {
    font-size: 12px;
    line-height: 18px;
    color: var(--secondary-color);
  }
  
  .calendar-icon {
    height: 15px;
    width: 15px;
    object-fit: contain;
  }
  
  .view-details-card {
    display: flex;
    gap: 15px;
  }
  
  .view-content-main {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 30px;
    row-gap: 10px;
    width: 100%;
  }
  
  .view-content-flex {
    display: flex;
    gap: 12px;
  }
  
  .view-content-flex .view-content-lable {
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
    color: var(--bg-color);
    /* min-width: 120px; */
    /* min-width: max-content; */
  }
  
  .view-content-flex .view-content-data {
    font-size: 12px;
    line-height: 17px;
    color: var(--secondary-color);
    /* -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden; */
  }
  
  .admin-view-btn-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
    flex-wrap: wrap;
  }
  
  .edit-icons {
    margin-right: 7px;
  }
  
  .iframe-main-tag {
    height: 300px;
    width: 100%;
    border: none;
  }
  
  .admin-modal .admin-ifram-modal-inner-main {
    max-width: 600px;
  }
  
  .admin-modal-inner .iframe-modal-close-btn,
  .admin-modal-inner .iframe-modal-close-btn:hover {
    position: absolute;
    right: -15px;
    top: -15px;
    background-color: var(--bg-color);
    padding: 6px;
    min-width: max-content;
    border-radius: 50%;
  }
  
  .reaction-icon {
    height: 20px;
    width: 20px;
    object-fit: contain;
    border-radius: 50%;
  }
  
  .admin-drop-form-control .MuiChip-root {
    border: 1px solid var(--main-border);
    color: var(--secondary-color);
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    justify-content: flex-start;
    height: 26px;
    width: max-content;
  }
  
  .admin-drop-form-control .MuiAutocomplete-tag {
    margin: 0px;
  }
  
  .admin-auto-complete-control .admin-auto-complete-filed {
    border: 1px solid var(--main-border);
    border-radius: 4px;
  }
  
  .input-complete-box
    .admin-auto-complete-control
    .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
    padding: 0px;
  }
  
  .input-complete-box .admin-auto-complete-control .MuiAutocomplete-tag {
    margin: 0px 3px 0px 0px;
    height: 20px;
    border: 1px solid var(--main-border);
    background-color: transparent;
  }
  
  .input-complete-box .MuiChip-label {
    color: var(--secondary-color);
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    padding-left: 5px;
    font-family: "Poppins", sans-serif;
  }
  
  .input-complete-box .admin-auto-complete-control .MuiChip-deleteIcon {
    font-size: 15px;
  }
  
  .news-activity-table-main .table {
    min-width: 1800px;
  }
  
  .news-view-content-main {
    width: 100%;
  }
  
  .news-view-content-main .view-content-box {
    margin-bottom: 8px;
  }
  
  .view-highlight-box {
    border: 1px solid var(--main-border);
    border-radius: 5px;
    padding: 5px 10px;
    margin-top: 15px;
  }
  
  .view-highlight-box .view-highlight-lable {
    font-size: 12px;
    font-weight: 600;
    line-height: 17px;
    color: var(--bg-color);
    text-align: center;
    margin-bottom: 5px;
  }
  
  .view-highlight-box .view-highlight-data {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    line-height: 17px;
    color: var(--secondary-color);
  }
  .admin-date-picker-control .MuiButtonBase-root {
    margin-right: 0px;
  }
  .view-content-link-flex {
    display: flex;
    gap: 10px;
  }
  
  .admin-page-title-main .admin-title-left .admin-view-page-title::after {
    display: none;
  }
  .admin-title-left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .admin-title-left .back-btn,
  .admin-title-left .back-btn:hover {
    min-width: 30px;
    background-color: transparent;
    padding: 0;
  }
  .admin-user-list-flex.view-page-title-main {
    flex-direction: row;
  }
  .view-page-title-main .admin-userlist-btn-flex {
    width: initial;
  }
  .upload-news-img-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .admin-add-news-input-box .admin-file-browse-btn-main {
    height: 126px;
    width: 100%;
  }
  .admin-add-news-input-box .admin-file-browse-btn-main-live {
    border-radius: 50%;
  }
  .upload-video-input-flex {
    display: flex;
    gap: 15px;
  }
  .video-activity-logview-main {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-top: 10px;
  }
  .video-activity-logview-main .view-content-lable {
    min-width: max-content;
  }
  .upload-video-input-box .file-upload-button.video-upload-btn,
  .upload-video-input-box .file-upload-button.video-upload-btn:hover {
    border: none;
  }
  .admin-file-browse-btn-main.upload-video-input-box {
    margin: 10px auto 20px;
    border: 2px dashed var(--main-border);
    max-width: 300px;
    width: 100%;
  }
  .admin-modal .admin-add-user-modal-inner-main.upload-video-modal-inner {
    max-width: 500px;
  }
  .view-news-analytic-row {
    margin-top: 20px;
  }
  
  .title-text {
    font-size: 15px;
    color: #3c0000;
  }
  .text-center {
    text-align: center;
  }
  .view-category-content-main .view-content-flex {
    flex-direction: column;
  }
  .admin-news-table-main .table {
    min-width: 1200px;
  }
  .admin-input-box .default-img-btn {
    border: 1px solid var(--main-border);
    border-radius: 20px;
    color: var(--secondary-color);
    font-size: 9px;
    line-height: 12px;
    font-weight: 400;
    padding: 3px 4px;
    text-transform: capitalize;
    margin-left: 5px;
  }
  .admin-add-news-input-box .MuiInputAdornment-root button {
    min-width: 30px;
    padding: 0;
    justify-content: flex-end;
  }
  .upload-img-live {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
    border-radius: 50%;
    overflow: hidden;
  }
  
  .admin-file-browse-btn-main.admin-file-browse-btn-main-live
    .admin-file-browse-btn,
  .admin-file-browse-btn-main.admin-file-browse-btn-main-live
    .admin-file-browse-btn:hover {
    right: 1px;
    top: 8px;
  }
  
  .admin-add-news-input-box .file-upload-main .file-upload-button,
  .admin-add-news-input-box .file-upload-main .file-upload-button:hover {
    height: 126px;
  }
  .admin-add-news-input-box .thumbnail-box {
    max-width: 224px;
    position: relative;
  }
  
  .admin-add-news-input-box .thumbnail-box-reel {
    max-width: 126px;
    height: 224px;
    position: relative;
  }
  .admin-add-news-input-box .file-upload-main .file-upload-button-reel,
  .admin-add-news-input-box .file-upload-main .file-upload-button-reel:hover {
    height: 224px;
  }
  .admin-add-news-input-box .upload-news-img-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: auto;
    aspect-ratio: 16 / 8.2;
  }
  .admin-add-news-input-box .thumbnail-box-reel .upload-news-img-box {
    aspect-ratio: 8.2 / 16;
  }
  .admin-add-news-input-box .upload-img {
    max-width: 100%;
  }
  .admin-video-icon-img {
    height: 25px;
    width: 25px;
    object-fit: cover;
  }
  .filter-btns.filter-btns-news {
    margin: 5px 0px 0px;
  }
  .admin-add-news-input-box .upload-news-img-box-live {
    aspect-ratio: unset;
  }
  